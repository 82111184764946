@import "~@/styles/util/_index.scss";

.p-10 {
  padding: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-50 {
  padding-top: 50px;
}
.btn-w100 {
  width: 110px;
}
.parent {
  white-space: nowrap;
}
.child {
  display: inline-block;
}
.clebex-button-text {
  font-size: 14px !important;
}
